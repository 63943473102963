.hallOfFame {
  /* width: 100%;
  text-align: center; */
}

.hallOfFame .catgoryContainer {
  margin-top: 10px;
  margin-bottom: 25px;
}

.hallOfFame .header {
  background-color: #ffed92;
  height: 50px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  color: #564590;
  font-weight: bold;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 20pt;
}
.hallOfFame .category_header {
  background-color: antiquewhite;
  padding: 10px;
  font-size: larger;
  border-radius: 10px;
}
.hallOfFame .userBox {
  position: relative;
  width: 230px;
  min-width: 170px;
  border-radius: 12px;
  margin: 8px;
  padding: 14px;
  background-color: #f5f5f5;
}

.hallOfFame .usersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hallOfFame .medalImage {
  position: absolute;
  left: 17px;
  width: 40px;
  z-index: 99;
}

.hallOfFame .photo_container {
  width: 100%;
  border-radius: 12px;
  position: relative;
}

.hallOfFame .photo {
  width: 100%;
  border-radius: 12px;
}

.hallOfFame .result_name_ghost {
  position: relative;
}

.hallOfFame .result {
  position: absolute;
  top: -47px;
  color: #e3c372;
  font-weight: bold;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 9pt;
  padding-left: 3px;
  z-index: 3;
}

.hallOfFame .userName {
  position: absolute;
  top: -31px;
  color: white;
  font-weight: 700;
  font-family: "Trebuchet MS", monospace;
  font-size: 12pt;
  padding-left: 3px;
  z-index: 3;
  text-transform: capitalize;
}

.hallOfFame .ghost {
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 55px;
  background: linear-gradient(0deg, rgb(0 0 2) 0%, rgb(0 0 0 / 0%) 100%);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  top: -55px;
}

.hallOfFame .about {
  color: #707070;
  font-size: 9pt;
  font-family: "Trebuchet MS", monospace;
  padding-top: 6px;
  text-align: justify;
  padding-bottom: 20px;
}

.hallOfFame .showFull {
  position: absolute;
  bottom: 10px;
  color: #727272;
  font-size: 11pt;
  font-family: "Trebuchet MS", monospace;
  font-weight: bolder;
  cursor: pointer;
}

.hallOfFame .modalBackground {
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  margin-top: 15px;
  min-width: 300px;
}

.hallOfFame .modalContainer {
  position: relative;
  max-width: 500px;
  /* min-width: 500px; */
  border-radius: 12px;
  margin: 8px;
  padding: 14px;
  background-color: #f5f5f5;
}

.hallOfFame .modalMedal {
  position: absolute;
  left: 20px;
  width: 60px;
  z-index: 99;
  width: calc(2vw + 30px);
}

.hallOfFame .modalImageContainer {
  position: relative;
  width: 100%;
  /* height: 472px; */
  border-radius: 12px;
}

.hallOfFame .modalResult {
  position: absolute;
  top: -52px;
  color: #e3c372;
  font-weight: bold;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 12pt;
  padding-left: 6px;
  z-index: 3;
}

.hallOfFame .modalName {
  position: absolute;
  top: -31px;
  color: white;
  font-weight: 700;
  font-family: "Trebuchet MS", monospace;
  font-size: 14pt;
  padding-left: 6px;
  z-index: 3;
  text-transform: capitalize;
}

.hallOfFame .modalGhost {
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 65px;
  background: linear-gradient(0deg, rgb(0 0 2) 0%, rgb(0 0 0 / 0%) 100%);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  top: -65px;
}

.hallOfFame .modalAbout {
  color: #707070;
  font-size: 12pt;
  font-family: "Trebuchet MS", monospace;
  padding-top: 6px;
  text-align: justify;
}
