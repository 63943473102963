.menu {
  height: 41px;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
}

@media (max-width:  768px) {
  .menu {
    height: auto; 
    padding: 0px;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    background-color: white;
    margin-bottom: 20px;
  }
}
.button {
  color: red;
}

.vertical-menu {
  height: 41px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
}
@media (max-width:  768px) {
  .vertical-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    margin-bottom: 20px;
  }
}

.appBarLink {
  text-decoration: none;
  margin: 5px;
  width: 100%;
}

.menuButtons {
  box-shadow: none;
  border-radius: 0px;
  border: 0px;
  background-color: white;
  color: #582b71;
  height: 40px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
}
@media (max-width:  768px) {
  .menuButtons {
    box-shadow: none;
    border-radius: 0px;
    border: 0px;
    background-color: white;
    color: #582b71;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px; 
    margin-bottom: 5px; 
  }
}

.menuButtons:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
