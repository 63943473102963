.App {
  text-align: center;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  background: url(../public/assets/images/main-bg.jpg) no-repeat top center
    #e6e7e8;
  max-width: 1170px;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  /* font-size: 30px; */
  letter-spacing: -0.02em;
  color: #582b71;
  line-height: 35px;
  font-size: calc(7px + 1vmin);
  flex-wrap: nowrap;
}
.bannerRow {
  position: relative;
  margin-bottom: 12px;
}
.desktop-banner {
  display: none; 
}

.mobile-banner {
  display: block; 
}
.left-logo {
  width: calc(100px + 13vw);
}
.right-logo {
  order: 3;
  width: calc(83px + 8vw);
}
.header-text {
  margin: 20px;
  order: 2;
  width: 100%;
}
@media (max-width: 700px) {
  .right-logo {
    order: 2;
  }
  .header-text {
    order: 3;
  }
  .headerRow {
    flex-wrap: wrap;
  }
  .addBox {
    flex-wrap: wrap;
  }
}

.content {
  background-color: white;
  padding: 30px;
}

.footer {
  margin-top: 20px;
  padding: 16px;
  background: white;
  margin-bottom: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 768px) { 
  .desktop-banner {
    display: block; 
  }
  .mobile-banner {
    display: none;
  }
}