.main-body {
  display: flex;
  flex-direction: column;
}

.list-items {
  text-align: left;
}

.resultsDiv {
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  color: #ffffff;
  background: radial-gradient(#76b2fe, #b69efe);
}

.iframeContainerContainer {
  max-width: 900px;
  margin: auto;
}
.iframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* max-width: 700px; */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
