.history2021 .yearSelectionBox {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.history2021 .scoreTableContainer {
  width: 389px !important;
  margin: 10px;
  border: solid #d4cbcb 1px;
  padding: 5px;
}

.history2021 .groupTitle {
  font-size: 1.3rem;
  color: #603083;
  font-weight: 700;
}

.firstDegree {
  font-weight: 900 !important;
  color: #ff7f04 !important;
}
