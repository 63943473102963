.scoreTableContainer .yearSelectionBox {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.scoreTableContainer {
  width: 389px !important;
  margin: 10px;
  border: solid #d4cbcb 1px;
  padding: 5px;
}

.scoreTableContainer .groupTitle {
  font-size: 1.3rem;
  color: #603083;
  font-weight: 700;
}

.scoreTableContainer .firstDegree {
  font-weight: 900 !important;
  color: #ff7f04 !important;
}
.leaderBoardScore {
  color: #ffffff;
  background-color: #888880;
  width: 49px;
  text-align: center;
  border-radius: 13px;
}
.leaderBoardPhoto {
  width: 100%;
  border-radius: 10px;
}
