.profilePageContainer {
  display: flex;
  flex-direction: column;
}

.verificationBox {
  display: flex;
  height: 500px;
  flex-direction: column;
}

.verificationbuttonDiv {
  margin-top: 30px;
}

.pageHeader {
  background-color: #b0bec5;
  padding: 10px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personelInfoBox {
  border: solid #b0bec5 3px;
  background: #f2f2f2;
  border-radius: 12px;
  padding: 20px;

}

.hallOfFameBox {
  border: solid #b0bec5 3px;
  background: #f2f2f2;
  margin-top: 8px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.labelRow {
  display: flex;
  margin-bottom: 6px;
}

.labelRowAbout {
  display: flex;
  margin-bottom: 6px;
  width: 100%;
  margin-top: 10px;
}

.label {
  width: 139px;
  text-align: left;
  font-weight: 600;
}

.titleHallOfFame {
  width: 100%;
  text-align: left;
  font-weight: 600;
}

.yearSelector {
  color: black !important;
}

.historyButton {
  background-color: #582b71 !important;
  color: white !important;

}
.editButton {
  background-color: darksalmon !important;
  color: black !important;

}

.buttonsspace {
display: flex;
justify-content: space-between; 
 margin-top: 20px;
}
@media (max-width: 600px) {
  .buttonsspace {
    display: flex;
    flex-direction: column;
    gap: 10px; 

  }
}

.uploadButton {
  background-color: rgb(122, 187, 233) !important;
  color: black !important;
  position: absolute !important;
  bottom: 7px !important;
  right: 7px !important;
}

.profileButtonsContainer {
  display: flex;
  justify-content: center;
}

.profileButtons {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 260px;
  /* border: solid; */
  /* align-self: flex-end; */
}

.activeProductBox {
  margin-top: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: solid #b0bec5 3px;
  background: #f2f2f2;
  border-radius: 12px;
}

.examBox {
  /* border: solid blue 1px; */
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: 460px;
  padding: 20px;
  border-radius: 13px;
  background: #cbddcb;
}

.lock-screen {
  position: absolute;
  background-color: #010404ad;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  color: darkorange;
  display: flex;
  flex-direction: column;
  font-size: xx-large;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.lock-screen-inner-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.purchaseButton {
  width: 200px;
  height: 80px;
  background-color: #bf9862 !important;
  font-size: large !important;
  margin-top: 30px !important;
}

.small-announcement {
  color: #cc6666;
  font-size: 0.9rem;
  font-style: italic;
}