.pageBox {
  display: flex;
  flex-direction: column;
}

.tabContainer {
  display: flex;
  align-items: flex-start;
}
.usersTab {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.userListBox {
  display: flex;
  flex-direction: column;
  border: solid red 1px;
  flex: 2;
  margin-right: 15px;
  border-radius: 7px;
  padding: 10px;
}

.searchRow {
  display: flex;
  margin-bottom: 20px;
}

.singleUserBox {
  display: flex;
  flex-direction: column;
  border: solid red 1px;
  flex: 1;
  border-radius: 7px;
  align-items: flex-start;
  padding: 10px;
}

.singleUserDataRow {
  margin-bottom: 10px;
}

.lbl {
  width: 100px;
  display: inline-block;
  text-align: left;
  font-weight: 600;
}
