.aboutPageContainer {
  display: flex;
  flex-direction: column;
}

.dispRow {
  display: flex;
  text-align: left;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.aboutParagraph {
  font-style: italic;
  vertical-align: bottom;
  font-weight: 100;
  font-size: 30px;
}

.aboutPageContainer.list-items {
  text-align: left;
}

.aboutPageContainer .subtitleText {
  text-align: justify;
  margin-bottom: 30px;
}

.kurulHeader {
  color: #603083;
}

.aboutPageContainer .innerColumn {
  min-width: 300px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
