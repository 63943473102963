.faq {
  color: #603083;
  text-align: center;
}

.helpPage {
  text-align: left;
}

.editContent {
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 55px;
}

.accordionEdit {
  font-weight: bold;
}
